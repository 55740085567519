import { CLIENT_COMMANDS, EXPEDITIONS, INVOICES, PROFORMAT, PROVISIONAL_SLIPS, QUOTES, RESERVATIONS, STOCKS, TICKETS, TRIPS,  } from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import Chart from "../dashboard/components/chart";
import ClientCommands from "./components/client-commands";
import Expeditions from "./components/expeditions";
import Invoices from "./components/invoices";
import QuoteRequests from "./components/quote-requests";
import Quotes from "./components/quotes";
import Reservations from "./components/reservations";
import Stocks from "./components/stocks";
import Tickets from "./components/tickets";
import Trips from "./components/trips";

export default function CrmClientPage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenCrmClient = () => {
    switch (selectChildren) {
      case INVOICES:
        return <Invoices />;
      case CLIENT_COMMANDS:
        return <ClientCommands />;
      case STOCKS:
        return <Stocks />;
      case QUOTES:
        return <Quotes />;
      case PROFORMAT:
        return <QuoteRequests />;
      case TRIPS:
        return <Trips />;
      case TICKETS:
        return <Tickets />;
      case RESERVATIONS:
        return <Reservations />;
      case EXPEDITIONS:
        return <Expeditions />;
      case PROVISIONAL_SLIPS:
        return <QuoteRequests />;
      
      default:
        return <Invoices />;
    }
  };
  return (
    <div className="xl:px-4 pt-14 h-full bg-white overflow-auto">
      {ChildrenCrmClient()}
    </div>
  );
}

import React, { Fragment } from "react";
import { AddIcon, SearchIcon } from "../components/icons";
import { Spinner, UserAvatar, UserMessage } from "../components";
// import { userChats } from "../utils/mocks";
import classNames from "classnames";
import {
  ChatBubbleLeftIcon,
  ClockIcon,
  GlobeAltIcon,
  MegaphoneIcon,
  PlusCircleIcon,
  PlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { getChatList, getContactAvatar, getContactDetails, getContatsList, getTemplates } from "../services/chat-service";
import { Chat } from "../models/message-model";
import { Contact, WhatsappContact } from "../models/contact-model";
import { isArray } from "lodash";
import { toast } from "react-toastify";
import { toastConfig } from "../utils/utils";
import ChatContactList from "../components/chat-contact-list";
import { fetchContacts } from "../services/contacts-service";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_CONTACT } from "../core/constants/layout";
import AddContactDialog from "../pages/campagnes/components/contact/add-contact-dialog";
import { NotificationTemplate } from "../models/notification-template-model";
import { User } from "../models/auth-model";
import { fetchUsers } from "../services/auth-service";

const secondTabsChat = [
  {
    name: "Tous",
    href: "#",
    current: true,
    state: 0,
    icon: GlobeAltIcon,
    notif: false,
  },
  {
    name: "Pending",
    href: "#",
    current: false,
    state: 1,
    icon: ClockIcon,
    notif: true,
  },
  {
    name: "Chats",
    href: "#",
    current: false,
    state: 2,
    icon: ChatBubbleLeftIcon,
    notif: true,
  },
  {
    name: "Groupes",
    href: "#",
    current: false,
    state: 3,
    icon: UserGroupIcon,
    notif: true,
  },
  {
    name: "Agents",
    href: "#",
    current: false,
    state: 4,
    icon: UserCircleIcon,
    notif: false,
  },
  
];
const secondTabsContacts = [
  {
    name: "Whatsapp",
    href: "#",
    current: true,
    state: 0,
    icon: ChatBubbleLeftIcon,
    notif: false,
  },
  {
    name: "Groupes Whatsapp",
    href: "#",
    current: true,
    state: 2,
    icon: UserGroupIcon,
    notif: false,
  },
  
  {
    name: "Campagne",
    href: "#",
    current: false,
    state: 1,
    icon: MegaphoneIcon,
    notif: true,
  }
];
const chat_user_types = [
  {
    id: 1,
    value: "WHATSAPP"
  },
  {
    id: 2,
    value: "DB_CONTACT"
  },
  {
    id: 3,
    value: "USER" //or agent
  },
  
];
interface MessagesProps {
  openAside: boolean;
  showContactList?: boolean;
  chatDisplay: number;
  setPhone: (phone: string) => void;
  openChat: (index: number) => void;
  setChannelType: (ct: number) => void;
}

export default function Messages({
  openAside,
  chatDisplay,
  showContactList,
  setPhone,
  openChat,
  setChannelType,
}: MessagesProps) {
  
  const [chats, setChats] = React.useState<Chat[]>([]);
  const [currentTab, setcurrentTab] = React.useState<number>(0);
  const [whatsappContacts, setWhatsappContacts] = React.useState<WhatsappContact[]>([]);
  const [dbContacts, setDbContacts] = React.useState<Contact[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [chatsReceived, setChatsReceived] = React.useState<Boolean>(false);
  const [contactsReceived, setContactsReceived] = React.useState<Boolean>(false);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [openContactCreate, setOpenContactCreate] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem("user")!)
  
  const navigate = useNavigate();
  
  
  React.useEffect(() => {
    if (!chatsReceived && typeof showContactList == "undefined") {
      setLoading(true);
      getChatList().then((data) => {
        if (isArray(data)) {
          setChats(data);
          setChatsReceived(true);
        }
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est produite", toastConfig)
      }).finally(() => {
        setLoading(false)
      });

      fetchUsers().then((u) => {
        setUsers(u);
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est produite", toastConfig)
      })
    }
    if (!contactsReceived && typeof showContactList !== "undefined" ) {
      setLoading(true);
      
      getContatsList().then((data: WhatsappContact[]) => {
        if (isArray(data)) {
          setWhatsappContacts(data);
          fetchContacts().then((cs: Contact[]) => {
            setDbContacts(cs)
            setContactsReceived(true);
          })
        }
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est produite", toastConfig)
      }).finally(() => {
        setLoading(false)
        setReload(false)
      });
    }
    if (reload) {
      setLoading(true);
      
      getContatsList().then((data: WhatsappContact[]) => {
        if (isArray(data)) {
          setWhatsappContacts(data);
          fetchContacts().then((cs: Contact[]) => {
            setDbContacts(cs)
            setContactsReceived(true);
          })
        }
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est produite", toastConfig)
      }).finally(() => {
        setLoading(false)
        setReload(false)
      });
    }
    
  },[reload, showContactList])
  return (
    <aside
      className={classNames(
        { "left-40": openAside },
        { "left-20": !openAside },
        " transition-all duration-300 ease-in-out fixed inset-y-0 hidden w-80 overflow-y-auto border-r border-gray-200 xl:block mt-12"
      )}
    >
      {typeof showContactList !== "undefined"? (
        <div className="absolute">
          <button
          style={{ "zIndex": 2 }} 
          title="Nouveau Contact"
          onClick={() => setOpenContactCreate(true)}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-2 rounded-full shadow-lg fixed ml-64 mt-[80vh]">
            <PlusIcon width={20} height={20} />
          </button>
        </div>

      ):(<></>)}
      <div className="flex justify-between border-b items-center">
        <nav className="-mb-px flex space-x-4 px-4 w-full" aria-label="Tabs">
          {typeof showContactList == "undefined" && secondTabsChat.map((tab) => (
            <div
              key={tab.name}
              onClick={() => setcurrentTab(tab.state)}
              className={classNames(
                currentTab === tab.state
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "group relative flex flex-col items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer overflow-auto"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.notif ? (
                <hr className="absolute right-0 p-1 bg-red-400 rounded-full" />
              ) : (
                <></>
              )}
              <tab.icon className="h-6 w-6" />
              <span>{tab.name}</span>
            </div>
          ))}
          {typeof showContactList !== "undefined" && secondTabsContacts.map((tab) => (
            <div
              key={tab.name}
              onClick={() => setcurrentTab(tab.state)}
              className={classNames(
                currentTab === tab.state
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "group relative flex flex-col items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <tab.icon className="h-6 w-6" />
              <span>{tab.name}</span>
            </div>
          ))}
          
        </nav>
      </div>
      <div className="w-full px-3">
        {typeof showContactList == "undefined"? (
          // search messages
          <div className="h-16 bg-primary-white w-full">
            <div className="h-full flex flex-col justify-center items-center relative">
              <SearchIcon className=" h-4 w-4 absolute left-4 text-gray-500" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search messages..."
                className="bg-gray-100 text-gray-500 text-xs placeholder:text-gray-400 placeholder:text-sm placeholder:font-light font-light h-10 w-full rounded-xl outline-none pl-12 pr-16"
              />
            </div>
          </div>
        ):(
          // search contacts
          <div className="h-16 bg-primary-white w-full">
            <div className="h-full flex flex-col justify-center items-center relative">
              <SearchIcon className=" h-4 w-4 absolute left-4 text-gray-500" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search contacts..."
                className="bg-gray-100 text-gray-500 text-xs placeholder:text-gray-400 placeholder:text-sm placeholder:font-light font-light h-10 w-full rounded-xl outline-none pl-12 pr-16"
              />
            </div>
          </div>
        )}
        {typeof showContactList == "undefined"?(
          <>
            {/* all chats */}
            {currentTab == 0? (
              <div className={"w-full divide-y"}>
                {!loading && chats.map((item, key) => {
                  return (
                    <UserMessage
                      className={classNames(
                        { "bg-gray-100": key === chatDisplay },
                        "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                      )}
                      key={key}
                      chat={item}
                      setPhone={() => {
                        setChannelType(1)
                        setPhone(item.phone)
                      }}
                      openChat={() => openChat(key)}
                    />
                  );
                })}
                {loading?(
                  <div className="flex justify-center">
                    <Spinner size="sm" color="green" />
                  </div>
                ):(<></>)}
              </div>
            ):(
              <></>
            )}
            {currentTab == 4? (
              <div className={"w-full divide-y"}>
                {!loading && users.map((item, key) => {
                  if (item.email != user?.email) {
                    return (
                      <ChatContactList
                        className={classNames(
                          { "bg-gray-100": key === chatDisplay },
                          "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                        )}
                        key={key}
                        contact={{ 
                          address: "", 
                          city: "", 
                          contactGroups: [], 
                          email: "",
                          createdAt: "",
                          id: key,
                          name: item.name,
                          phone: item.email,
                          template: "" 
                        }}
                        setPhone={() => {
                          setChannelType(3)
                          setPhone(item.email)
                        }} //use emails for agents
                        openChat={() => openChat(key)}
                      />
                    );
                  }
                })}
                {loading?(
                  <div className="flex justify-center">
                    <Spinner size="sm" color="green" />
                  </div>
                ):(<></>)}
              </div>
            ):(
              <></>
            )}
            
          </>

        ):(
          <>
          {/* contact list */}
            {currentTab == 0? (
              <div className={"w-full divide-y"}>
                {!loading && whatsappContacts.map((item, key) => {
                  if (!item.isGroup) {
                    return (
                      <ChatContactList
                        className={classNames(
                          { "bg-gray-100": key === chatDisplay },
                          "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                        )}
                        key={key}
                        contact={{ 
                          address: "", 
                          city: "", 
                          contactGroups: [], 
                          email: "",
                          createdAt: "",
                          id: key,
                          name: item.name,
                          phone: item.id,
                          template: "" 
                        }}
                        setPhone={() => {
                          setChannelType(2)
                          setPhone(item.id)
                        }}
                        openChat={() => openChat(key)}
                      />
                    );
                  }
                })}
                {loading?(
                  <div className="flex justify-center">
                    <Spinner size="sm" color="green" />
                  </div>
                ):(<></>)}
              </div>
            ):(
              <></>
            )}
            {currentTab == 1? (
              <div className={"w-full divide-y"}>
              {!loading && dbContacts.map((item, key) => {
                return (
                  <ChatContactList
                    className={classNames(
                      { "bg-gray-100": key === chatDisplay },
                      "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                    )}
                    key={key}
                    contact={item}
                    setPhone={() => {
                      setChannelType(2)
                      let p = item.phone.replaceAll("+",""); 
                      if (p.includes("@") || p.includes("@c.us")) {
                        setPhone(p)
                      } else {
                        setPhone(p + "@c.us")
                      }
                    }}
                    openChat={() => openChat(key)}
                  />
                );
              })}
              {loading?(
                <div className="flex justify-center">
                  <Spinner size="sm" color="green" />
                </div>
              ):(<></>)}
            </div>
            ):(
              <></>
            )}
            {currentTab == 2? (
              <div className={"w-full divide-y"}>
                {!loading && whatsappContacts.map((item, key) => {
                  if (item.isGroup) {
                    return (
                      <ChatContactList
                        className={classNames(
                          { "bg-gray-100": key === chatDisplay },
                          "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                        )}
                        key={key}
                        contact={{ 
                          address: "", 
                          city: "", 
                          contactGroups: [], 
                          email: "",
                          createdAt: "",
                          id: key,
                          name: item.name,
                          phone: item.id,
                          template: "" 
                        }}
                        setPhone={() => {
                          setChannelType(2)
                          setPhone(item.id)
                        }}
                        openChat={() => openChat(key)}
                      />
                    );
                  }
                })}
                {loading?(
                  <div className="flex justify-center">
                    <Spinner size="sm" color="green" />
                  </div>
                ):(<></>)}
              </div>
            ):(
              <></>
            )}
            
          </>
        )}
      </div>
      <AddContactDialog open={openContactCreate} setOpen={() => setOpenContactCreate(!openContactCreate)} setReload={setReload} color="green" />
    </aside>
  );
}

import React from "react";
import { Message, MessageContent } from "../models/message-model";
import { getmessages } from "../services/chat-service";
import { getDateFromTimestamp, toastConfig } from "../utils/utils";
import { Discuss } from "react-loader-spinner";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import { time, timeStamp } from "console";
import { toast } from "react-toastify";

interface ChatMessageProps {
  phone: string;
  avatar: string;
  refresh: boolean;
  setChats: (c:Message) => void;
}

export default function ChatMessage({ phone, avatar,refresh, setChats }: ChatMessageProps) {

  const [chat, setChat] = React.useState<Message>();
  
  const [loading, setLoading] = React.useState<Boolean>(false);

  const [reload, setReload] = React.useState<Boolean>(refresh);

  const loadChatMessage = async function () {
    await getmessages(phone).then((data) => {
      // let oldMessages: MessageContent[] = [];
      // data.messages.forEach(msg => {
      //   if(!chat?.messages.includes(msg)) {
      //     oldMessages.push(msg);
      //   }
      //   // for (let index = 0; index < chat!.messages.length; index++) {
      //   //   if (chat!.messages[index].id === 0 && chat!.messages[index].message === msg.message) {
      //   //     chat!.messages[index] = msg;
      //   //   }
      //   // }
      // })
      //  const newChat = {
      //   ...data,
      //   messages: [...data.messages, ...oldMessages!]
      // };
      setChat(data);
    }).catch((e) => {
      toast.error("Erreur Technique, veillez reessayer plus tard", toastConfig);
    }).finally(() => {
      setLoading(false);
    });
  };

  // function addChats(newMessage: any) {
    
  //   let message: MessageContent = {
  //     datetime: Date.now(),
  //     id: 0,
  //     message: newMessage,
  //     owner: phone,
  //     status: "device",
  //     type: "text"
  //   }; 
  //   chat?.messages?.push(message);
  //   setChat(chat);
  // }
  React.useEffect(() => {
    // console.log("yoooo")
    // console.log(loading)
    // if (!loading) {
    //   setLoading(true);
    // }
    loadChatMessage()
    const interval = setInterval(() => loadChatMessage(), 60 * 1000)
    // refresh = false;
    // setLoading(false);
    return () => clearInterval(interval)
  },[phone, refresh,loading]);

  return (
    <div className="w-full flex-grow my-2 p-2 overflow-y-auto">
      { !loading? chat?.messages.map((message, key) => {
        return (
          <>
          {message.owner == phone?

              <div className={"flex items-end w-3/4"}>
                {avatar != "" && typeof avatar != "undefined"?(
                  <>
                    <img
                      src={avatar}
                      className="w-8 h-8 m-3 rounded-full"
                      alt="avatar"
                    />
                  </>
                  ):(
                    <>
                      <img
                        src="/assets/images/img-1.jpg"
                        className="w-8 h-8 m-3 rounded-full"
                        alt="avatar"
                      />
                    </>
                  )
                }
                <div className="p-3 bg-gray-100 mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
                  <div className="text-black text-xs ">
                    {message.message}
                  </div>
                  <div className="text-xs text-gray-400 mt-1">{getDateFromTimestamp( message.datetime)}</div>
                </div>
              </div>
            :
            <div className="flex justify-end">
            <div className="flex items-end w-3/4 bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 max-w-xl md:w-auto">
              <div className="p-2">
                <div className="text-white text-xs">
                  {message.message}
                  {/* <Markdown remarkPlugins={[remarkGfm]}>{message.message}</Markdown> */}
                </div>
                <div className="text-xs text-white text-right mt-1">{getDateFromTimestamp( message.datetime)}</div>
              </div>
            </div>
          </div>
          }
        </>
        );
      }): (
        <div className="flex items-center justify-center h-full">
          <Discuss
            visible={true}
            height="80"
            width="80"
            ariaLabel="discuss-loading"
            wrapperStyle={{}}
            wrapperClass="discuss-wrapper"
            colors={["#32a852","#32a852"]}
            // backgroundColor="#F4442E"
            />
        </div>
      )
      }
    </div>
  );
}

import React, { FormEvent } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { login } from '../../services/auth-service';
import { Auth } from '../../models/auth-model';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { toastConfig } from '../../utils/utils';
import { Spinner } from '../../components';


export default function LoginPage() {
  const signIn = useSignIn();
  const navigate = useNavigate()

  const [formData, setFormData] = React.useState({email: '', password: ''});
  const [loading, setLoading] = React.useState(false);

  async function authenticate(e: FormEvent) {
    e.preventDefault();
    setLoading(true)
    await login(formData).then((res: Auth) => {
      if(signIn({
          auth: {
              token: res.token,
              type: 'Bearer'
          },
          userState: res
        })) { 
          sessionStorage.setItem("user",JSON.stringify({
            name: res.user.name,
            email: res.user.email,
            roles: res.user.roles,
            microservices: res.apps,
            deployed_app: res.options[0]
          }))
          sessionStorage.setItem("token",res.token)
          toast.success("Bienvenu",toastConfig);
          navigate(`/dashboard`);
      }else {
          //Throw error
          toast.error("Mot de passe ou Email Incorrect",toastConfig);
      }
    }).catch((e) => {
      toast.error("Mot de passe ou Email Incorrect",toastConfig);
    }).finally(() => {
      setLoading(false);
    })
  }
  
  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Connectez-vous à votre compte
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-6" onSubmit={authenticate}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Adresse e-mail
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        onInput={(e) => setFormData({...formData, email: e.currentTarget.value})}
                        className="block w-full px-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Mot de passe
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        onInput={(e) => setFormData({...formData, password: e.currentTarget.value})}
                        className="block w-full px-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <div className="text-sm leading-6">
                      <a
                        href="#"
                        className="font-semibold text-green-600 hover:text-green-500"
                      >
                        J'ai oublié le mot de passe
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      type="submit"
                      disabled={loading}
                    >
                      <span className={loading?'hidden':""}>
                        Se connecter
                      </span>
                      <span className={!loading?'hidden':""}>
                        <Spinner size='sm' color='white' />
                      </span>
                    </button>
                  </div>
                </form>
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">Or</span>
                  </div>
                </div>

                <div className="mt-6 flex justify-center gap-4">
                  <h4 className="text-sm font-light">
                    Vous n'avez pas encore de compte?{" "}
                    <a href="/" className="text-green-600">
                      Inscrivez-vous ici
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block bg-gray-100">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/images/login-illustrator.png"
            alt=""
          />
        </div>
      </div>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
    </>
  );
}

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CallIcon,
  DashboardIcon,
  HistoryIcon,
  MessageIcon,
} from "../../components/icons";
import { NavigationInterface } from "../models";
import {
  BanknotesIcon,
  BookmarkIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  CircleStackIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  ForwardIcon,
  IdentificationIcon,
  InboxStackIcon,
  MagnifyingGlassCircleIcon,
  MegaphoneIcon,
  PaperClipIcon,
  PencilSquareIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  RectangleGroupIcon,
  RssIcon,
  ShieldExclamationIcon,
  ShoppingCartIcon,
  SignalIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  TicketIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ArrowUturnUpIcon } from "@heroicons/react/20/solid";

const navigationsList: NavigationInterface[] = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: Squares2X2Icon,
    children: [
      {
        name: "Marketing",
        href: "#",
        icon: BuildingStorefrontIcon,
      },
      {
        name: "Commercial",
        href: "#db-commercial",
        icon: ShoppingCartIcon,
      },
      {
        name: "Operationnel",
        href: "#db-opperationnel",
        icon: PuzzlePieceIcon,
      },
      {
        name: "Satif. Client",
        href: "#db-satisfaction-client",
        icon: UserGroupIcon,
      },
      {
        name: "Chats",
        href: "#db-conversation-chat",
        icon: ChatBubbleLeftRightIcon,
      },
      {
        name: "Finances",
        href: "#db-finances",
        icon: CurrencyDollarIcon,
      },
    ],
    color: "gray",
  },
  {
    name: "Conversations",
    href: "/conversations",
    icon: ChatBubbleLeftRightIcon,
    children: [
      {
        name: "Dashboard",
        href: "#dashboard",
        icon: Squares2X2Icon,
      },
      { name: "Messages", href: "#", icon: MessageIcon },

      { name: "Contacts", href: "#contacts", icon: CallIcon, separator: true },
      { name: "Demandes", href: "#demandes", icon: MagnifyingGlassCircleIcon },
      { name: "Incidents", href: "#incidents", icon: ShieldExclamationIcon },
      { name: "Reclamations", href: "#reclamations", icon: SignalIcon },
    ],
    color: "green",
    // separator: true
  },

  {
    name: "Campagnes",
    href: "/campagnes",
    icon: MegaphoneIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: Squares2X2Icon,
      },
      {
        name: "Leads",
        href: "#cpg-leads",
        icon: UserGroupIcon,
      },
      {
        name: "Suivi des campagnes",
        href: "#cpg-follow",
        icon: ForwardIcon,
      },
      {
        name: "Créer",
        href: "#cpg-new",
        icon: SquaresPlusIcon,
      },
      {
        name: "Contact",
        href: "#cpg-contact",
        icon: IdentificationIcon,
      },
      {
        name: "Template",
        href: "#cpg-template",
        icon: RectangleGroupIcon,
      },
      {
        name: "Media",
        href: "#cpg-media",
        icon: PhotoIcon,
      },
      {
        name: "CX Flows",
        href: "#cpg-cx",
        icon: PhotoIcon,
      },
    ],
    color: "purple",
  },
  {
    name: "CRM Clients",
    href: "/crm-client",
    icon: UsersIcon,
    children: [
      {
        name: "Factures",
        href: "#",
        icon: BanknotesIcon,
        app_type:"YANGZI"
      },
      {
        name: "Commandes Clients",
        href: "#client-commands",
        icon: BookmarkIcon,
        app_type:"YANGZI"
      },
      {
        name: "Stocks",
        href: "#stocks",
        icon: InboxStackIcon,
        app_type:"YANGZI"
      },
      {
        name: "Cotations",
        href: "#quotes",
        icon: PaperClipIcon,
        app_type:"YANGZI"
      },
      {
        name: "Proformats",
        href: "#proforma",
        icon: PencilSquareIcon,
        app_type:"YANGZI",
        separator: true
      },
      {
        name: "Voyages",
        href: "#trips",
        icon: TruckIcon,
        app_type:"PASSENGER"
      },
      {
        name: "Tickets",
        href: "#tickets",
        icon: TicketIcon,
        app_type:"PASSENGER"
      },
      {
        name: "Reservations",
        href: "#reservations",
        icon: ClipboardDocumentIcon,
        app_type:"PASSENGER",
        separator: true
      },
      {
        name: "Expeditions",
        href: "#expeditions",
        icon: TruckIcon,
        app_type:"CLEMENTINO"
      },
      {
        name: "Bordereaux Provisoires",
        href: "#provisional-slips",
        icon: ClipboardDocumentIcon,
        app_type:"CLEMENTINO"
      },
      
      
    ],
    color: "blue",
  },
  {
    name: "Big Data",
    href: "/bigdata",
    icon: CircleStackIcon,
    children: [
      {
        name: "Dasboard",
        href: "#",
        icon: CircleStackIcon,
      },
      {
        name: "Data Analysis",
        href: "#data-analysis",
        icon: CircleStackIcon,
      },
      {
        name: "Data Sources",
        href: "#data-sources",
        icon: CircleStackIcon,
      },
      {
        name: "Data Collector",
        href: "#data-collector",
        icon: CircleStackIcon,
      },
      {
        name: "Data Queries",
        href: "#data-query",
        icon: CircleStackIcon,
      },
    ],
    color: "red",
  },
  {
    name: "Finances",
    href: "/finance",
    icon: CurrencyDollarIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: Squares2X2Icon,
      },
    ],
    color: "brown",
  },
];

interface Props {
  children?: ReactNode;
}

export const LayoutContext = createContext({
  selectChildren: "",
  navigations: [{}],
  asideOpen: true,
  handleOpenAsude: () => {},
  handleChildren: (value: string) => {},
});

export const LayoutProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [asideOpen, setAsideOpen] = useState<boolean>(true);

  const [navigations, setNavigations] = useState<NavigationInterface[]>(
    navigationsList
  );

  const [selectChildren, setSelectChildren] = useState<string>(
    location.hash ? location.hash : "#"
  );

  useEffect(() => {}, []);

  const handleOpenAsude = () => {
    setAsideOpen(!asideOpen);
  };

  const handleChildren = (value: string) => {
    setSelectChildren(value);
    navigate(value);
  };

  const value = {
    handleOpenAsude,
    asideOpen,
    navigations,
    selectChildren,
    handleChildren,
  };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
export const useLayoutContext = () => useContext(LayoutContext);

import React from "react";
import { Message, MessageContent } from "../models/message-model";
import { getmessages } from "../services/chat-service";
import { getDateFromTimestamp, toastConfig } from "../utils/utils";
import { Discuss } from "react-loader-spinner";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import { time, timeStamp } from "console";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import { ApiRoute } from "../utils/api-route";
import { AgentChatMessage, AgentConversation } from "../models/agent-chat-model";

interface ChatMessageAgentsProps {
  phone: string;
  avatar: string;
  refresh: boolean;
  updateConversation: (e: Array<AgentChatMessage>) => void;
  conversations: AgentConversation 
}
interface ChatMessage {
  message: string;
  from: string;
  to: string;
  timestamp: number;
  // id: string;
}

export default function ChatMessageAgents({ phone, avatar,refresh, updateConversation, conversations }: ChatMessageAgentsProps) {

  const [chat, setChat] = React.useState<ChatMessage[]>([]);
  
  const [loading, setLoading] = React.useState<Boolean>(false);

  const [chatsFetched, setChatsFetched] = React.useState<Boolean>(false);

  const [reload, setReload] = React.useState<Boolean>(refresh);

  const user = JSON.parse(sessionStorage.getItem("user")!)

  const websocket = React.useRef<WebSocket | null>(null);

  const loadChatMessage = async function () {
    let storageChats = localStorage.getItem("agent_chats_"+ phone);
    if (storageChats != null) {
      storageChats = JSON.parse(storageChats);
      if (isArray(storageChats)) {
        setChat(storageChats);
      }
    }
    // await getmessages(phone).then((data) => {
    //   setChat(data);
    // }).catch((e) => {
    //   toast.error("Erreur Technique, veillez reessayer plus tard", toastConfig);
    // }).finally(() => {
    //   setLoading(false);
    // });
  };


  React.useEffect(() => {
    loadChatMessage()
    if (phone) {
      websocket.current = new WebSocket("wss://"+ApiRoute.websocketUrl);

      websocket.current.onopen = () => {
          websocket.current?.send(JSON.stringify({ event: 'register', clientId: phone }));
      };

      websocket.current.onmessage = (event) => {
          const messageData = JSON.parse(event.data);
          const { event: messageEvent, to, from, data } = messageData;

          if (messageEvent === 'text' && from) {
              updateConversation([data]);
          }

          if (messageEvent === 'getMessagesBySentId') {
              updateConversation(data);
          }
      };

      websocket.current.onerror = (error) => {
          console.error('WebSocket error:', error);
      };

      websocket.current.onclose = () => {
          console.log('WebSocket connection closed');
      };

      return () => {
          if (websocket.current) {
              websocket.current.close();
          }
      };
  }
  },[phone, refresh,loading]);

  return (
    <div className="w-full flex-grow my-2 p-2 overflow-y-auto">
      { !loading? conversations?.messages?.map((message, key) => {
        if (message.from == user?.email || message.to == user?.email) {
          return (
            <>
            {message.from == phone?
                <div className={"flex items-end w-3/4"}>
                  {avatar != "" && typeof avatar != "undefined"?(
                    <>
                      <img
                        src={avatar}
                        className="w-8 h-8 m-3 rounded-full"
                        alt="avatar"
                      />
                    </>
                    ):(
                      <>
                        <img
                          src="/assets/images/img-1.jpg"
                          className="w-8 h-8 m-3 rounded-full"
                          alt="avatar"
                        />
                      </>
                    )
                  }
                  <div className="p-3 bg-gray-100 mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
                    <div className="text-black text-xs ">
                      {message.text}
                    </div>
                    <div className="text-xs text-gray-400 mt-1">{new Date(message.time).toLocaleTimeString()}</div>
                  </div>
                </div>
              :
              <div className="flex justify-end">
                <div className="flex items-end w-3/4 bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 max-w-xl md:w-auto">
                  <div className="p-2">
                    <div className="text-white text-xs">
                      {message.text}
                      {/* <Markdown remarkPlugins={[remarkGfm]}>{message.message}</Markdown> */}
                    </div>
                    <div className="text-xs text-white text-right mt-1">{new Date(message.time).toLocaleTimeString()}</div>
                  </div>
                </div>
              </div>
            }
          </>
          );
        }
      }): (
        <div className="flex items-center justify-center h-full">
          <Discuss
            visible={true}
            height="80"
            width="80"
            ariaLabel="discuss-loading"
            wrapperStyle={{}}
            wrapperClass="discuss-wrapper"
            colors={["#32a852","#32a852"]}
            // backgroundColor="#F4442E"
            />
        </div>
      )
      }
    </div>
  );
}

import { TableColumn } from "react-data-table-component"
import { Menu, Transition } from "@headlessui/react";
import { ArrowDownIcon, ArrowLeftIcon, ArrowsRightLeftIcon, ArrowsUpDownIcon, EllipsisVerticalIcon, EyeIcon, TrashIcon, TruckIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react/jsx-runtime";
import { Travel } from "../../../models/crm/passenger/travel-model";
import { getFormattedDate } from "../../../utils/utils";

const columns: TableColumn<Travel>[] = [
  {
    name: 'CODE',
    selector: (row: Travel) => row.code,
  },
  {
    name: 'TRAJET',
    selector: (row: Travel) => row.code,
    cell(row) {
      return (
      <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span className="text-purple-600 text-center">
            {row.departure.Name} {row.type == "ROUND_TRIP"?<ArrowsUpDownIcon height={25} width={25} className="mx-auto" />:<ArrowDownIcon height={25} width={25} className="mx-auto" />} {row.arrival.Name}
          </span>
        </div>
      );
  },
  },
  {
    name: 'TYPE TRAJET',
    selector: (row: Travel) => row.code,
    cell(row) {
      return (
      <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span className={"inline-flex items-center rounded-md bg-"+(row.type == "ROUND_TRIP"?"blue":"green")+"-200 px-2 py-1 text-xs font-medium text-"+(row.type == "ROUND_TRIP"?"blue":"green")+"-800 ring-1 ring-inset ring-"+(row.type == "ROUND_TRIP"?"blue":"green")+"-600/20"}>
            {row.type == "ROUND_TRIP"?"Aller Retour":"Aller Simple"}
          </span>
        </div>
      );
    },
  },
  {
    name: 'PRIX',
    selector: (row: Travel) => row.price,
    cell(row) {
      return (
        <div className=" px-3 py-4 text-sm text-gray-500">
          <span 
            className={"inline-flex items-center rounded-md bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"}>
            {row.price != null?"XAF "+ row.price: "-"}
          </span>
        </div>
      );
    },
  },
  {
    name: 'DATE/HEURE DE DEPART',
    selector: (row: Travel) => getFormattedDate(row.departureDate,true,true),
  },
  {
    name: 'CAPACITE',
    selector: (row: Travel) => row.capacity,
    cell(row) {
      return (
        <div className=" px-3 py-4 text-sm text-gray-500">
          <span 
          className={"inline-flex items-center rounded-md bg-blue-200 px-2 py-1 text-xs font-medium text-blue-800 ring-1 ring-inset ring-blue-600/20"}>
          {row.capacity} places
          </span>
        </div>
      );
    },
  },
  {
    name: 'BUS',
    selector: (row: Travel) => row.bus.id,
    cell(row) {
      return (
        <div className=" px-3 py-4 text-sm text-gray-500">
          {row.bus.name}<br />
          <span 
            className={"inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-600/20 flex"}>
            <UserCircleIcon height={20} width={20} /> {row?.driver?.nom}
          </span>
        </div>
      );
    },
  },
  {
    name: 'STATUS',
    selector: (row: Travel) => row.id,
    cell(row) {
      if (row.droppedAt != null) {
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-600/20"}>
            arrive
            </span>
          </div>
        );
      } else if(row.takeoffAt != null && row.droppedAt == null) {
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-orange-200 px-2 py-1 text-xs font-medium text-orange-800 ring-1 ring-inset ring-orange-600/20"}>
            en cours
            </span>
          </div>
        );
      } else {
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"}>
            en attente
            </span>
          </div>
        );
      }
    },
  },
  {
    name: 'ACTIONS',
    selector: (row: Travel) => row.id,
    cell: (row: Travel) => {
      return (
        <Menu
          as="div"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        
                      >
                        {active ? (
                          <EyeIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <EyeIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Voir
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active
                          ? "bg-purple-500 text-white"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <TrashIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <TrashIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      Supprimer
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      );
    }
  },
  
  
];

export default columns;
